@import "~@ng-select/ng-select/themes/default.theme.css";

html{
	scroll-behavior: smooth;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 8px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 8px;
	height:6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #a0a0a0;
}
.docs-api-h2 {
	font-size: 30px
}

.docs-api-h3 {
	font-size: 24px;
	font-weight: 400;
	margin-top: 45px
}

.docs-api-h4 {
	font-size: 18px;
	font-weight: 400
}

.docs-api-class-description {
	font-size: 12px
}

.docs-api-property-name {
	margin: 0
}

.docs-api-method-name-row,
.docs-api-method-parameter-row,
.docs-api-properties-name-cell {
	font-family: 'Roboto Mono', monospace;
	font-weight: 600
}

.docs-api-method-parameter-row,
.docs-api-properties-name-cell {
	font-size: 14px
}

.docs-api-method-parameter-type {
	font-size: 12px
}

.docs-api-class-name,
.docs-api-module-import {
	display: inline
}

.docs-api-method-name-cell {
	font-weight: 700;
	font-size: 18px
}

.docs-api-method-parameters-header-cell,
.docs-api-method-returns-header-cell {
	font-size: 14px
}

.docs-api-deprecated-marker,
.docs-api-input-marker,
.docs-api-output-marker {
	font-size: 12px
}

.docs-api-class-export-name,
.docs-api-class-selector-name,
.docs-api-module-import {
	font-family: 'Roboto Mono', monospace;
	padding: 3px
}

.docs-api-class-extends-type {
	text-decoration: none;
	border-bottom: 1px dotted;
	color: inherit;
	font-weight: 400;
	font-size: 18px
}

.docs-api-class-deprecated-marker,
.docs-api-deprecated-marker,
.docs-api-interface-deprecated-marker {
	display: inline-block;
	font-weight: 700
}

.docs-api-class-deprecated-marker[title],
.docs-api-deprecated-marker[title],
.docs-api-interface-deprecated-marker[title] {
	border-bottom: 1px dotted grey;
	cursor: help
}

.docs-api-deprecated-marker+.docs-api-property-name {
	text-decoration: line-through
}

.docs-markdown {
	max-width: 100%
}

.docs-markdown h1 {
	display: inline-block;
	font-size: 34px;
	font-weight: 400;
	padding: 5px
}

.docs-markdown h2 {
	font-size: 24px
}

.docs-markdown h3 {
	font-size: 20px
}

.docs-markdown h2,
.docs-markdown h4 {
	margin-top: 40px
}

.docs-markdown h5 {
	font-size: 18px
}

.docs-markdown ol,
.docs-markdown p,
.docs-markdown ul {
	font-size: 16px;
	line-height: 28px
}

.docs-markdown td code {
	font-size: 14px
}

.docs-markdown pre {
	border-radius: 5px;
	display: block;
	margin: 16px auto;
	overflow-x: auto;
	padding: 20px;
	white-space: pre-wrap
}

.docs-markdown pre code {
	padding: 0;
	font-size: 100%;
	background: 0 0
}

.docs-markdown code {
	padding: 3px
}

.docs-header-link header-link a {
	text-decoration: none;
	margin-left: -30px;
	display: inline-block;
	vertical-align: middle
}

.docs-header-link .material-icons {
	visibility: hidden
}

.docs-header-link:hover .material-icons {
	visibility: visible
}

.docs-api table,
.docs-markdown-table {
	border-collapse: collapse;
	border-radius: 2px;
	border-spacing: 0;
	margin: 0 0 32px;
	width: 100%;
	box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12)
}

.docs-api th,
.docs-markdown-th {
	font-weight: 400;
	max-width: 100px;
	padding: 13px 32px;
	text-align: left
}

.docs-api td,
.docs-markdown-td {
	font-weight: 400;
	padding: 8px 16px
}

@media (max-width:720px) {
	.docs-api table,
	.docs-markdown-table {
		margin: 0 0 32px
	}
	.docs-api th,
	.docs-markdown-th {
		padding: 6px 16px
	}
	.docs-api td,
	.docs-markdown-td {
		padding: 4px 8px
	}
}

body, .wrapper{
	/* font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
	font-family: Montserrat,"Helvetica Neue",Arial,sans-serif !important;
	font-size: 14px;
	margin: 0;
	line-height: 1.6;
}

.docs-button[md-button],
.docs-button[md-raised-button] {
	text-transform: uppercase
}

h1,
h2 {
	font-weight: 400
}

.docs-primary-header {
	padding-left: 20px
}

.docs-primary-header h1 {
	font-weight: 300;
	margin: 0;
	padding: 28px 8px;
	font-size: 20px;
	color: #fff
}

.docs-component-category-list-card-image svg {
	width: 100%
}

.docs-footer-angular-logo svg {
	height: 50px
}

.docs-angular-logo svg {
	height: 26px;
	margin: 0 4px 3px 0;
	vertical-align: middle
}

.docs-github-logo svg {
	height: 21px;
	margin: 0 7px 2px 0;
	vertical-align: middle
}

code,
pre {
	font-family: 'Roboto Mono', monospace
}

code {
	font-size: 90%
}

pre {
	font-size: 14px
}

.mat-badge-content {
	font-weight: 600;
	font-size: 12px;
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-badge-small .mat-badge-content {
	font-size: 9px
}

.mat-badge-large .mat-badge-content {
	font-size: 24px
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
	font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
	margin: 0 0 16px
}

.mat-h2,
.mat-title,
.mat-typography h2 {
	font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
	margin: 0 0 16px
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
	font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
	margin: 0 0 16px
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
	font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
	margin: 0 0 16px
}

.mat-h5,
.mat-typography h5 {
	font: 400 calc(14px * .83)/20px Roboto, "Helvetica Neue", sans-serif;
	margin: 0 0 12px
}

.mat-h6,
.mat-typography h6 {
	font: 400 calc(14px * .67)/20px Roboto, "Helvetica Neue", sans-serif;
	margin: 0 0 12px
}

.mat-body-2,
.mat-body-strong {
	font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif
}

.mat-body,
.mat-body-1,
.mat-typography {
	font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
	margin: 0 0 12px
}

.mat-caption,
.mat-small {
	font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-display-4,
.mat-typography .mat-display-4 {
	font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
	letter-spacing: -.05em;
	margin: 0 0 56px
}

.mat-display-3,
.mat-typography .mat-display-3 {
	font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
	letter-spacing: -.02em;
	margin: 0 0 64px
}

.mat-display-2,
.mat-typography .mat-display-2 {
	font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
	letter-spacing: -.005em;
	margin: 0 0 64px
}

.mat-display-1,
.mat-typography .mat-display-1 {
	font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
	margin: 0 0 64px
}

.mat-bottom-sheet-container {
	font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 14px;
	font-weight: 500
}

.mat-button-toggle,
.mat-card {
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-card-title {
	font-size: 24px;
	font-weight: 500
}

.mat-card-header .mat-card-title {
	font-size: 20px
}

.mat-card-content,
.mat-card-subtitle {
	font-size: 14px
}

.mat-checkbox {
	/* font-family: Roboto, "Helvetica Neue", sans-serif */
}

.mat-checkbox-layout .mat-checkbox-label {
	line-height: 24px
}

.mat-chip {
	font-size: 14px;
	font-weight: 500
}

.mat-chip .mat-chip-remove.mat-icon,
.mat-chip .mat-chip-trailing-icon.mat-icon {
	font-size: 18px
}

.mat-table {
	/* font-family: Roboto, "Helvetica Neue", sans-serif */
}

.mat-header-cell {
	font-size: 14px;
	font-weight: 500
}

.mat-cell,
.mat-footer-cell {
	font-size: 14px
}

.mat-calendar {
	/* font-family: Roboto, "Helvetica Neue", sans-serif */
}

.mat-calendar-body {
	font-size: 13px
}

.mat-calendar-body-label,
.mat-calendar-period-button {
	font-size: 14px;
	font-weight: 500
}

.mat-calendar-table-header th {
	font-size: 11px;
	font-weight: 400
}

.mat-dialog-title {
	font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif
}

.mat-expansion-panel-header {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 15px;
	font-weight: 400
}

.mat-expansion-panel-content {
	font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif
}

.mat-form-field {
	font-size: inherit;
	font-weight: 400;
	line-height: 1.125;
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-form-field-wrapper {
	padding-bottom: 1.34375em
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
	font-size: 150%;
	line-height: 1.125
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
	height: 1.5em;
	width: 1.5em
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
	height: 1.125em;
	line-height: 1.125
}

.mat-form-field-infix {
	padding: .5em 0;
	border-top: .84375em solid transparent
}

.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
	transform: translateY(-1.34375em) scale(.75);
	width: 133.33333333%
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.34374em) scale(.75);
	width: 133.33334333%
}

.mat-form-field-label-wrapper {
	top: -.84375em;
	padding-top: .84375em
}

.mat-form-field-label {
	top: 1.34375em
}

.mat-form-field-underline {
	bottom: 1.34375em
}

.mat-form-field-subscript-wrapper {
	font-size: 75%;
	margin-top: .66666667em;
	top: calc(100% - 1.79166667em)
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
	padding: .4375em 0
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
	transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
	-ms-transform: translateY(-1.28125em) scale(.75);
	width: 133.33333333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00101px);
	-ms-transform: translateY(-1.28124em) scale(.75);
	width: 133.33334333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00102px);
	-ms-transform: translateY(-1.28123em) scale(.75);
	width: 133.33335333%
}

.mat-form-field-appearance-legacy .mat-form-field-label {
	top: 1.28125em
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
	margin-top: .54166667em;
	top: calc(100% - 1.66666667em)
}

@media print {
	.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
	.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
		transform: translateY(-1.28122em) scale(.75)
	}
	.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
		transform: translateY(-1.28121em) scale(.75)
	}
	.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
		transform: translateY(-1.2812em) scale(.75)
	}
}

.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: .25em 0 .75em
}

.mat-form-field-appearance-fill .mat-form-field-label {
	top: 1.09375em;
	margin-top: -.5em
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
	transform: translateY(-.59375em) scale(.75);
	width: 133.33333333%
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-.59374em) scale(.75);
	width: 133.33334333%
}

.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 1em 0
}

.mat-form-field-appearance-outline .mat-form-field-label {
	top: 1.84375em;
	margin-top: -.25em
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
	transform: translateY(-1.59375em) scale(.75);
	width: 133.33333333%
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
	transform: translateY(-1.59374em) scale(.75);
	width: 133.33334333%
}

.mat-grid-tile-footer,
.mat-grid-tile-header {
	font-size: 14px
}

.mat-grid-tile-footer .mat-line,
.mat-grid-tile-header .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box
}

.mat-grid-tile-footer .mat-line:nth-child(n+2),
.mat-grid-tile-header .mat-line:nth-child(n+2) {
	font-size: 12px
}

input.mat-input-element {
	margin-top: -.0625em
}

.mat-menu-item {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 14px;
	font-weight: 400
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 12px
}

.mat-radio-button,
.mat-select {
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-select-trigger {
	height: 1.125em
}

.mat-slide-toggle-content {
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-slider-thumb-label-text {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 12px;
	font-weight: 500
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-step-label {
	font-size: 14px;
	font-weight: 400
}

.mat-step-sub-label-error {
	font-weight: 400
}

.mat-step-label-error {
	font-size: 14px
}

.mat-step-label-selected {
	font-size: 14px;
	font-weight: 500
}

.mat-tab-group {
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-tab-label,
.mat-tab-link {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 14px;
	font-weight: 500
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
	font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
	margin: 0
}

.mat-tooltip {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 10px;
	padding-top: 6px;
	padding-bottom: 6px
}

.mat-tooltip-handset {
	font-size: 14px;
	padding-top: 8px;
	padding-bottom: 8px
}

.mat-list-item,
.mat-list-option {
	font-family: Roboto, "Helvetica Neue", sans-serif
}

.mat-list-base .mat-list-item {
	font-size: 16px
}

.mat-list-base .mat-list-item .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
	font-size: 14px
}

.mat-list-base .mat-list-option {
	font-size: 16px
}

.mat-list-base .mat-list-option .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box
}

.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
	font-size: 14px
}

.mat-list-base[dense] .mat-list-item {
	font-size: 12px
}

.mat-list-base[dense] .mat-list-item .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2),
.mat-list-base[dense] .mat-list-option {
	font-size: 12px
}

.mat-list-base[dense] .mat-list-option .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
	font-size: 12px
}

.mat-list-base[dense] .mat-subheader {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 12px;
	font-weight: 500
}

.mat-option {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 16px;
	color: rgba(0, 0, 0, .87)
}

.mat-optgroup-label {
	font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
	color: rgba(0, 0, 0, .54)
}

.mat-simple-snackbar {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 14px
}

.mat-simple-snackbar-action {
	line-height: 1;
	font-family: inherit;
	font-size: inherit;
	font-weight: 500
}

.mat-ripple {
	overflow: hidden;
	position: relative
}

.mat-ripple.mat-ripple-unbounded {
	overflow: visible
}

.mat-ripple-element {
	position: absolute;
	border-radius: 50%;
	pointer-events: none;
	transition: opacity, transform 0s cubic-bezier(0, 0, .2, 1);
	transform: scale(0)
}

@media (-ms-high-contrast:active) {
	.mat-ripple-element {
		display: none
	}
}

.cdk-visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	outline: 0;
	-webkit-appearance: none;
	-moz-appearance: none
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 1000002
}

.cdk-overlay-container:empty {
	display: none
}

.cdk-global-overlay-wrapper {
	display: flex;
	position: absolute;
	z-index: 1000
}

.cdk-overlay-pane {
	position: absolute;
	pointer-events: auto;
	box-sizing: border-box;
	z-index: 1000;
	display: flex;
	max-width: 100%;
	max-height: 100%
}

.cdk-overlay-backdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	pointer-events: auto;
	-webkit-tap-highlight-color: transparent;
	transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
	opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 1
}

@media screen and (-ms-high-contrast:active) {
	.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
		opacity: .6
	}
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
	position: absolute;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	min-width: 1px;
	min-height: 1px
}

.cdk-global-scrollblock {
	position: fixed;
	width: 100%;
	overflow-y: scroll
}

@-webkit-keyframes cdk-text-field-autofill-start {
	/*!*/
}

@keyframes cdk-text-field-autofill-start {
	/*!*/
}

@-webkit-keyframes cdk-text-field-autofill-end {
	/*!*/
}

@keyframes cdk-text-field-autofill-end {
	/*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
	-webkit-animation-name: cdk-text-field-autofill-start;
	animation-name: cdk-text-field-autofill-start
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
	-webkit-animation-name: cdk-text-field-autofill-end;
	animation-name: cdk-text-field-autofill-end
}

textarea.cdk-textarea-autosize {
	resize: none
}

textarea.cdk-textarea-autosize-measuring {
	height: auto!important;
	overflow: hidden!important;
	padding: 2px 0!important;
	box-sizing: content-box!important
}

.mat-ripple-element {
	background-color: rgba(0, 0, 0, .1)
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
	background: rgba(0, 0, 0, .04)
}

.mat-option.mat-active {
	background: rgba(0, 0, 0, .04);
	color: rgba(0, 0, 0, .87)
}

.mat-option.mat-option-disabled {
	color: rgba(0, 0, 0, .38)
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
	color: #3f51b5
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
	color: #ff4081
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
	color: #f44336
}

.mat-optgroup-disabled .mat-optgroup-label {
	color: rgba(0, 0, 0, .38)
}

.mat-pseudo-checkbox {
	color: rgba(0, 0, 0, .54)
}

.mat-pseudo-checkbox::after {
	color: #fafafa
}

.mat-pseudo-checkbox-disabled {
	color: #b0b0b0
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
	background: #ff4081
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background: #3f51b5
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
	background: #f44336
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
	background: #b0b0b0
}

.mat-elevation-z0 {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z1 {
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z2 {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z3 {
	box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z4 {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z5 {
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z6 {
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z7 {
	box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10px 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12)
}

.mat-elevation-z8 {
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z9 {
	box-shadow: 0 5px 6px -3px rgba(0, 0, 0, .2), 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z10 {
	box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z11 {
	box-shadow: 0 6px 7px -4px rgba(0, 0, 0, .2), 0 11px 15px 1px rgba(0, 0, 0, .14), 0 4px 20px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z12 {
	box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z13 {
	box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z14 {
	box-shadow: 0 7px 9px -4px rgba(0, 0, 0, .2), 0 14px 21px 2px rgba(0, 0, 0, .14), 0 5px 26px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z15 {
	box-shadow: 0 8px 9px -5px rgba(0, 0, 0, .2), 0 15px 22px 2px rgba(0, 0, 0, .14), 0 6px 28px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z16 {
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z17 {
	box-shadow: 0 8px 11px -5px rgba(0, 0, 0, .2), 0 17px 26px 2px rgba(0, 0, 0, .14), 0 6px 32px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z18 {
	box-shadow: 0 9px 11px -5px rgba(0, 0, 0, .2), 0 18px 28px 2px rgba(0, 0, 0, .14), 0 7px 34px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z19 {
	box-shadow: 0 9px 12px -6px rgba(0, 0, 0, .2), 0 19px 29px 2px rgba(0, 0, 0, .14), 0 7px 36px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z20 {
	box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 20px 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z21 {
	box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 21px 33px 3px rgba(0, 0, 0, .14), 0 8px 40px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z22 {
	box-shadow: 0 10px 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z23 {
	box-shadow: 0 11px 14px -7px rgba(0, 0, 0, .2), 0 23px 36px 3px rgba(0, 0, 0, .14), 0 9px 44px 8px rgba(0, 0, 0, .12)
}

.mat-elevation-z24 {
	box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
}

.mat-app-background {
	background-color: #fafafa;
	color: rgba(0, 0, 0, .87)
}

.mat-theme-loaded-marker {
	display: none
}

.mat-autocomplete-panel {
	background: #fff;
	color: rgba(0, 0, 0, .87)
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
	background: #fff
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
	color: rgba(0, 0, 0, .87)
}

@media (-ms-high-contrast:active) {
	.mat-badge-content {
		outline: solid 1px;
		border-radius: 0
	}
	.mat-checkbox-disabled {
		opacity: .5
	}
}

.mat-badge-accent .mat-badge-content {
	background: #ff4081;
	color: #fff
}

.mat-badge-warn .mat-badge-content {
	color: #fff;
	background: #f44336
}

.mat-badge {
	position: relative
}

.mat-badge-hidden .mat-badge-content {
	display: none
}

.mat-badge-disabled .mat-badge-content {
	background: #b9b9b9;
	color: rgba(0, 0, 0, .38)
}

.mat-badge-content {
	color: #fff;
	background: #3f51b5;
	position: absolute;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	transition: transform .2s ease-in-out;
	transform: scale(.6);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none
}

.mat-badge-content._mat-animation-noopable,
.ng-animate-disabled .mat-badge-content {
	transition: none
}

.mat-badge-content.mat-badge-active {
	transform: none
}

.mat-badge-small .mat-badge-content {
	width: 16px;
	height: 16px;
	line-height: 16px
}

.mat-badge-small.mat-badge-above .mat-badge-content {
	top: -8px
}

.mat-badge-small.mat-badge-below .mat-badge-content {
	bottom: -8px
}

.mat-badge-small.mat-badge-before .mat-badge-content {
	left: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
	left: auto;
	right: -16px
}

.mat-badge-small.mat-badge-after .mat-badge-content {
	right: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
	right: auto;
	left: -16px
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -8px
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -8px
}

.mat-badge-medium .mat-badge-content {
	width: 22px;
	height: 22px;
	line-height: 22px
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
	top: -11px
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
	bottom: -11px
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
	left: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
	left: auto;
	right: -22px
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
	right: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
	right: auto;
	left: -22px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -11px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -11px
}

.mat-badge-large .mat-badge-content {
	width: 28px;
	height: 28px;
	line-height: 28px
}

.mat-badge-large.mat-badge-above .mat-badge-content {
	top: -14px
}

.mat-badge-large.mat-badge-below .mat-badge-content {
	bottom: -14px
}

.mat-badge-large.mat-badge-before .mat-badge-content {
	left: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
	left: auto;
	right: -28px
}

.mat-badge-large.mat-badge-after .mat-badge-content {
	right: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
	right: auto;
	left: -28px
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -14px
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -14px
}

.mat-bottom-sheet-container {
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
	background: #fff;
	color: rgba(0, 0, 0, .87)
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
	color: inherit;
	background: 0 0
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
	color: #3f51b5
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
	color: #ff4081
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
	color: #f44336
}

.mat-button.mat-accent[disabled],
.mat-button.mat-primary[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-primary[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
	color: rgba(0, 0, 0, .26)
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
	background-color: #3f51b5
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
	background-color: #ff4081
}

.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
	background-color: #f44336
}

.mat-button[disabled] .mat-button-focus-overlay,
.mat-icon-button[disabled] .mat-button-focus-overlay,
.mat-stroked-button[disabled] .mat-button-focus-overlay {
	background-color: transparent
}

.mat-button .mat-ripple-element,
.mat-icon-button .mat-ripple-element,
.mat-stroked-button .mat-ripple-element {
	opacity: .1;
	background-color: currentColor
}

.mat-button-focus-overlay {
	background: #000
}

.mat-stroked-button:not([disabled]) {
	border-color: rgba(0, 0, 0, .12)
}

.mat-fab,
.mat-flat-button,
.mat-mini-fab,
.mat-raised-button {
	color: rgba(0, 0, 0, .87);
	background-color: #fff
}

.mat-fab.mat-accent,
.mat-fab.mat-primary,
.mat-fab.mat-warn,
.mat-flat-button.mat-accent,
.mat-flat-button.mat-primary,
.mat-flat-button.mat-warn,
.mat-mini-fab.mat-accent,
.mat-mini-fab.mat-primary,
.mat-mini-fab.mat-warn,
.mat-raised-button.mat-accent,
.mat-raised-button.mat-primary,
.mat-raised-button.mat-warn {
	color: #fff
}

.mat-fab.mat-accent[disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled] {
	color: rgba(0, 0, 0, .26);
	background-color: rgba(0, 0, 0, .12)
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
	background-color: #3f51b5
}

.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent {
	background-color: #ff4081
}

.mat-fab.mat-warn,
.mat-flat-button.mat-warn,
.mat-mini-fab.mat-warn,
.mat-raised-button.mat-warn {
	background-color: #f44336
}

.mat-fab.mat-accent .mat-ripple-element,
.mat-fab.mat-primary .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-flat-button.mat-accent .mat-ripple-element,
.mat-flat-button.mat-primary .mat-ripple-element,
.mat-flat-button.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element,
.mat-raised-button.mat-accent .mat-ripple-element,
.mat-raised-button.mat-primary .mat-ripple-element,
.mat-raised-button.mat-warn .mat-ripple-element {
	background-color: rgba(255, 255, 255, .1)
}

.mat-flat-button:not([class*=mat-elevation-z]),
.mat-stroked-button:not([class*=mat-elevation-z]) {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-raised-button:not([class*=mat-elevation-z]) {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-fab:not([class*=mat-elevation-z]),
.mat-mini-fab:not([class*=mat-elevation-z]) {
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]),
.mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
	box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12)
}

.mat-fab[disabled]:not([class*=mat-elevation-z]),
.mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-button-toggle-group,
.mat-button-toggle-standalone {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, .12)
}

.mat-button-toggle {
	color: rgba(0, 0, 0, .38)
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
	background-color: rgba(0, 0, 0, .12)
}

.mat-button-toggle-appearance-standard {
	color: rgba(0, 0, 0, .87);
	background: #fff
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
	background-color: #000
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
	border-left: 1px solid rgba(0, 0, 0, .12)
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
	border-left: none;
	border-right: 1px solid rgba(0, 0, 0, .12)
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle+.mat-button-toggle {
	border-left: none;
	border-right: none;
	border-top: 1px solid rgba(0, 0, 0, .12)
}

.mat-button-toggle-checked {
	background-color: #e0e0e0;
	color: rgba(0, 0, 0, .54)
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
	color: rgba(0, 0, 0, .87)
}

.mat-button-toggle-disabled {
	color: rgba(0, 0, 0, .26);
	background-color: #eee
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
	background: #fff
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
	background-color: #bdbdbd
}

.mat-card {
	background: #fff;
	color: rgba(0, 0, 0, .87)
}

.mat-card:not([class*=mat-elevation-z]) {
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-card-subtitle {
	color: rgba(0, 0, 0, .54)
}

.mat-checkbox-frame {
	border-color: rgba(0, 0, 0, .54)
}

.mat-checkbox-checkmark {
	fill: #fafafa
}

.mat-checkbox-checkmark-path {
	stroke: #fafafa!important
}

@media (-ms-high-contrast:black-on-white) {
	.mat-checkbox-checkmark-path {
		stroke: #000!important
	}
}

.mat-checkbox-mixedmark {
	background-color: #fafafa
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
	background-color: #3f51b5
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background-color: #ff4081
}

.mat-checkbox-checked.mat-warn .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background {
	background-color: #f44336
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
	background-color: #b0b0b0
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
	border-color: #b0b0b0
}

.mat-checkbox-disabled .mat-checkbox-label {
	color: rgba(0, 0, 0, .54)
}

@media (-ms-high-contrast:active) {
	.mat-checkbox-background {
		background: 0 0
	}
}

.mat-checkbox .mat-ripple-element {
	background-color: #000
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
	background: #3f51b5
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
	background: #ff4081
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
	background: #f44336
}

.mat-chip.mat-standard-chip {
	background-color: #e0e0e0;
	color: rgba(0, 0, 0, .87)
}

.mat-chip.mat-standard-chip .mat-chip-remove {
	color: rgba(0, 0, 0, .87);
	opacity: .4
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
	box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12)
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
	opacity: .54
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
	opacity: .4
}

.mat-chip.mat-standard-chip::after {
	background: #000
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
	background-color: #3f51b5;
	color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
	color: #fff;
	opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
	background: rgba(255, 255, 255, .1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
	background-color: #f44336;
	color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
	color: #fff;
	opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
	background: rgba(255, 255, 255, .1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
	background-color: #ff4081;
	color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
	color: #fff;
	opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
	background: rgba(255, 255, 255, .1)
}

.mat-table {
	background: #fff
}

.mat-table tbody,
.mat-table tfoot,
.mat-table thead,
.mat-table-sticky,
[mat-footer-row],
[mat-header-row],
[mat-row],
mat-footer-row,
mat-header-row,
mat-row {
	background: inherit
}

mat-footer-row,
mat-header-row,
mat-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
	border-bottom-color:#eaeaea
}

.mat-header-cell {
	color: #333333;
}

.mat-cell,
.mat-footer-cell {
	color: rgba(0, 0, 0, .87)
}

.mat-calendar-arrow {
	border-top-color: rgba(0, 0, 0, .54)
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
	color: rgba(0, 0, 0, .54)
}

.mat-calendar-table-header {
	color: rgba(0, 0, 0, .38)
}

.mat-calendar-table-header-divider::after {
	background: rgba(0, 0, 0, .12)
}

.mat-calendar-body-label {
	color: rgba(0, 0, 0, .54)
}

.mat-calendar-body-cell-content {
	color: rgba(0, 0, 0, .87);
	border-color: transparent
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
	color: rgba(0, 0, 0, .38)
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
	background-color: rgba(0, 0, 0, .04)
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
	border-color: rgba(0, 0, 0, .38)
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected) {
	border-color: rgba(0, 0, 0, .18)
}

.mat-calendar-body-selected {
	background-color: #3f51b5;
	color: #fff
}

.mat-calendar-body-disabled>.mat-calendar-body-selected {
	background-color: rgba(63, 81, 181, .4)
}

.mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
	background-color: #fff;
	color: rgba(0, 0, 0, .87)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
	background-color: #ff4081;
	color: #fff
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected {
	background-color: rgba(255, 64, 129, .4)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
	background-color: #f44336;
	color: #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected {
	background-color: rgba(244, 67, 54, .4)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content-touch {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-datepicker-toggle-active {
	color: #3f51b5
}

.mat-datepicker-toggle-active.mat-accent {
	color: #ff4081
}

.mat-datepicker-toggle-active.mat-warn {
	color: #f44336
}

.mat-dialog-container {
	box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
	background: #fff;
	color: rgba(0, 0, 0, .87)
}
.mat-dialog-content{
	margin:0 !important;
}
.mat-divider {
	border-top-color: rgba(0, 0, 0, .12)
}

.mat-divider-vertical {
	border-right-color: rgba(0, 0, 0, .12)
}

.mat-expansion-panel {
	background: #fff;
	color: rgba(0, 0, 0, .87)
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-action-row {
	border-top-color: rgba(0, 0, 0, .12)
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
	background: rgba(0, 0, 0, .04)
}

@media (hover:none) {
	.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
		background: #fff
	}
}

.mat-expansion-panel-header-title {
	color: rgba(0, 0, 0, .87)
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
	color: rgba(0, 0, 0, .54)
}

.mat-expansion-panel-header[aria-disabled=true] {
	color: rgba(0, 0, 0, .26)
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title {
	color: inherit
}

.mat-form-field-label,
.mat-hint {
	color: rgba(0, 0, 0, .6)
}

.mat-form-field.mat-focused .mat-form-field-label {
	color: #3f51b5
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
	color: #ff4081
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
	color: #f44336
}

.mat-focused .mat-form-field-required-marker {
	color: #ff4081
}

.mat-form-field-ripple {
	background-color: rgba(0, 0, 0, .87)
}

.mat-form-field.mat-focused .mat-form-field-ripple {
	background-color: #3f51b5
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
	background-color: #ff4081
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
	background-color: #f44336
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
	color: #3f51b5
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
	color: #ff4081
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after,
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent {
	color: #f44336
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
	background-color: #f44336
}

.mat-error {
	color: #f44336
}

.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-form-field-appearance-legacy .mat-hint {
	color: rgba(0, 0, 0, .54)
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
	bottom: 1.25em;
	background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
	background-image: linear-gradient(to right, rgba(0, 0, 0, .42) 0, rgba(0, 0, 0, .42) 33%, transparent 0);
	background-size: 4px 100%;
	background-repeat: repeat-x
}

.mat-form-field-appearance-standard .mat-form-field-underline {
	background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
	background-image: linear-gradient(to right, rgba(0, 0, 0, .42) 0, rgba(0, 0, 0, .42) 33%, transparent 0);
	background-size: 4px 100%;
	background-repeat: repeat-x
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: rgba(0, 0, 0, .04)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
	background-color: rgba(0, 0, 0, .02)
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
	background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
	color: rgba(0, 0, 0, .38)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
	background-color: transparent
}

.mat-form-field-appearance-outline .mat-form-field-outline {
	color: rgba(0, 0, 0, .12)
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: rgba(0, 0, 0, .87)
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
	color: #3f51b5
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
	color: #ff4081
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
	color: #f44336
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
	color: rgba(0, 0, 0, .38)
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
	color: rgba(0, 0, 0, .06)
}

.mat-icon.mat-primary {
	color: #3f51b5
}

.mat-icon.mat-accent {
	color: #ff4081
}

.mat-icon.mat-warn {
	color: #f44336
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
	color: rgba(0, 0, 0, .54)
}

.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-input-element:disabled {
	color: rgba(0, 0, 0, .38)
}

.mat-input-element {
	caret-color: #3f51b5
}

.mat-input-element::-ms-input-placeholder {
	color: rgba(0, 0, 0, .42)
}

.mat-input-element::placeholder {
	color: rgba(0, 0, 0, .42)
}

.mat-input-element::-moz-placeholder {
	color: rgba(0, 0, 0, .42)
}

.mat-input-element::-webkit-input-placeholder {
	color: rgba(0, 0, 0, .42)
}

.mat-input-element:-ms-input-placeholder {
	color: rgba(0, 0, 0, .42)
}

.mat-accent .mat-input-element {
	caret-color: #ff4081
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
	caret-color: #f44336
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
	color: #f44336
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
	color: rgba(0, 0, 0, .87)
}

.mat-list-base .mat-subheader {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 14px;
	font-weight: 500;
	color: rgba(0, 0, 0, .54)
}

.mat-list-item-disabled {
	background-color: #eee
}

.mat-action-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-list-option:focus,
.mat-list-option:hover,
.mat-nav-list .mat-list-item:focus,
.mat-nav-list .mat-list-item:hover {
	background: rgba(0, 0, 0, .04)
}

.mat-menu-panel {
	background: #fff
}

.mat-menu-panel:not([class*=mat-elevation-z]) {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-menu-item {
	background: 0 0;
	color: rgba(0, 0, 0, .87)
}

.mat-menu-item[disabled],
.mat-menu-item[disabled]::after {
	color: rgba(0, 0, 0, .38)
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
	color: rgba(0, 0, 0, .54)
}

.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
	background: rgba(0, 0, 0, .04)
}

.mat-paginator {
	background: #fff
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
	color: rgba(0, 0, 0, .54)
}

.mat-paginator-decrement,
.mat-paginator-increment {
	border-top: 2px solid rgba(0, 0, 0, .54);
	border-right: 2px solid rgba(0, 0, 0, .54)
}

.mat-paginator-first,
.mat-paginator-last {
	border-top: 2px solid rgba(0, 0, 0, .54)
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-last {
	border-color: rgba(0, 0, 0, .38)
}

.mat-progress-bar-background {
	fill: #c5cae9
}

.mat-progress-bar-buffer {
	background-color: #c5cae9
}

.mat-progress-bar-fill::after {
	background-color: #3f51b5
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
	fill: #ff80ab
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
	background-color: #ff80ab
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
	background-color: #ff4081
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
	fill: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
	background-color: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
	background-color: #f44336
}

.mat-progress-spinner circle,
.mat-spinner circle {
	stroke: #3f51b5
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
	stroke: #ff4081
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
	stroke: #f44336
}

.mat-radio-outer-circle {
	border-color: rgba(0, 0, 0, .54)
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
	border-color: #3f51b5
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
	background-color: #3f51b5
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: #ff4081
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: #ff4081
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
	border-color: #f44336
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
	background-color: #f44336
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
	border-color: rgba(0, 0, 0, .38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element {
	background-color: rgba(0, 0, 0, .38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
	color: rgba(0, 0, 0, .38)
}

.mat-radio-button .mat-ripple-element {
	background-color: #000
}

.mat-select-value {
	color: rgba(0, 0, 0, .87)
}

.mat-select-placeholder {
	color: rgba(0, 0, 0, .42)
}

.mat-select-disabled .mat-select-value {
	color: rgba(0, 0, 0, .38)
}

.mat-select-arrow {
	color: rgba(0, 0, 0, .54)
}

.mat-select-panel {
	background: #fff
}

.mat-select-panel:not([class*=mat-elevation-z]) {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
	background: rgba(0, 0, 0, .12)
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
	color: #3f51b5
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
	color: #ff4081
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
	color: #f44336
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
	color: rgba(0, 0, 0, .38)
}

.mat-drawer-container {
	background-color: #fafafa;
	color: rgba(0, 0, 0, .87)
}

.mat-drawer {
	background-color: #fff;
	color: rgba(0, 0, 0, .87)
}

.mat-drawer.mat-drawer-push {
	background-color: #fff
}

.mat-drawer:not(.mat-drawer-side) {
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)
}

.mat-drawer-side {
	border-right: 1px solid rgba(0, 0, 0, .12)
}

.mat-drawer-side.mat-drawer-end,
[dir=rtl] .mat-drawer-side {
	border-left: 1px solid rgba(0, 0, 0, .12);
	border-right: none
}

[dir=rtl] .mat-drawer-side.mat-drawer-end {
	border-left: none;
	border-right: 1px solid rgba(0, 0, 0, .12)
}

.mat-drawer-backdrop.mat-drawer-shown {
	background-color: rgba(0, 0, 0, .6)
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: #ff4081
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(255, 64, 129, .54)
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
	background-color: #ff4081
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
	background-color: #3f51b5
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(63, 81, 181, .54)
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
	background-color: #3f51b5
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
	background-color: #f44336
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(244, 67, 54, .54)
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
	background-color: #f44336
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
	background-color: #000
}

.mat-slide-toggle-thumb {
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
	background-color: #fafafa
}

.mat-slide-toggle-bar {
	background-color: rgba(0, 0, 0, .38)
}

.mat-slider-track-background {
	background-color: rgba(0, 0, 0, .26)
}

.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill {
	background-color: #3f51b5
}

.mat-primary .mat-slider-thumb-label-text {
	color: #fff
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
	background-color: #ff4081
}

.mat-accent .mat-slider-thumb-label-text {
	color: #fff
}

.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label,
.mat-warn .mat-slider-track-fill {
	background-color: #f44336
}

.mat-warn .mat-slider-thumb-label-text {
	color: #fff
}

.mat-slider-focus-ring {
	background-color: rgba(255, 64, 129, .2)
}

.cdk-focused .mat-slider-track-background,
.mat-slider:hover .mat-slider-track-background {
	background-color: rgba(0, 0, 0, .38)
}

.mat-slider-disabled .mat-slider-thumb,
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled:hover .mat-slider-track-background {
	background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value .mat-slider-focus-ring {
	background-color: rgba(0, 0, 0, .12)
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
	background-color: rgba(0, 0, 0, .87)
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
	background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
	border-color: rgba(0, 0, 0, .26);
	background-color: transparent
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb {
	border-color: rgba(0, 0, 0, .38)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb {
	border-color: rgba(0, 0, 0, .26)
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
	border-color: rgba(0, 0, 0, .7)
}

.mat-slider-horizontal .mat-slider-ticks {
	background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent);
	background-image: -moz-repeating-linear-gradient(.0001deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-slider-vertical .mat-slider-ticks {
	background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
	background-color: rgba(0, 0, 0, .04)
}

@media (hover:none) {
	.mat-step-header:hover {
		background: 0 0
	}
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
	color: rgba(0, 0, 0, .54)
}

.mat-step-header .mat-step-icon {
	background-color: rgba(0, 0, 0, .54);
	color: #fff
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	background-color: #3f51b5;
	color: #fff
}

.mat-step-header .mat-step-icon-state-error {
	background-color: transparent;
	color: #f44336
}

.mat-step-header .mat-step-label.mat-step-label-active {
	color: rgba(0, 0, 0, .87)
}

.mat-step-header .mat-step-label.mat-step-label-error {
	color: #f44336
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
	background-color: #fff
}

.mat-stepper-vertical-line::before {
	border-left-color: rgba(0, 0, 0, .12)
}

.mat-horizontal-stepper-header::after,
.mat-horizontal-stepper-header::before,
.mat-stepper-horizontal-line {
	border-top-color: rgba(0, 0, 0, .12)
}

.mat-sort-header-arrow {
	color: #757575
}

.mat-tab-header,
.mat-tab-nav-bar {
	border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.mat-tab-group-inverted-header .mat-tab-header,
.mat-tab-group-inverted-header .mat-tab-nav-bar {
	border-top: 1px solid rgba(0, 0, 0, .12);
	border-bottom: none
}

.mat-tab-label,
.mat-tab-link {
	color: rgba(0, 0, 0, .87)
}

.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
	color: rgba(0, 0, 0, .38)
}

.mat-tab-header-pagination-chevron {
	border-color: rgba(0, 0, 0, .87)
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(0, 0, 0, .38)
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
	border-bottom: none;
	border-top: none
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(197, 202, 233, .3)
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	background-color: #3f51b5
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
	background-color: #fff
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(255, 128, 171, .3)
}

.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
	background-color: #ff4081
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
	background-color: #fff
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
	background-color: #f44336
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
	background-color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(197, 202, 233, .3)
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-header-pagination,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
	background-color: #3f51b5
}

.mat-tab-group.mat-background-primary .mat-tab-label,
.mat-tab-group.mat-background-primary .mat-tab-link,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link {
	color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
	color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
	border-color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
	background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(255, 128, 171, .3)
}

.mat-tab-group.mat-background-accent .mat-tab-header,
.mat-tab-group.mat-background-accent .mat-tab-header-pagination,
.mat-tab-group.mat-background-accent .mat-tab-links,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-accent .mat-tab-links {
	background-color: #ff4081
}

.mat-tab-group.mat-background-accent .mat-tab-label,
.mat-tab-group.mat-background-accent .mat-tab-link,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link {
	color: #fff
}

.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
	color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
	border-color: #fff
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
	background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
	background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-background-warn .mat-tab-header,
.mat-tab-group.mat-background-warn .mat-tab-header-pagination,
.mat-tab-group.mat-background-warn .mat-tab-links,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-warn .mat-tab-links {
	background-color: #f44336
}

.mat-tab-group.mat-background-warn .mat-tab-label,
.mat-tab-group.mat-background-warn .mat-tab-link,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link {
	color: #fff
}

.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
	color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
	border-color: #fff
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
	background-color: rgba(255, 255, 255, .12)
}

.mat-toolbar {
	background: #f5f5f5;
	color: rgba(0, 0, 0, .87)
}

.mat-toolbar.mat-primary {
	background: #3f51b5;
	color: #fff
}

.mat-toolbar.mat-accent {
	background: #ff4081;
	color: #fff
}

.mat-toolbar.mat-warn {
	background: #f44336;
	color: #fff
}

.mat-toolbar .mat-focused .mat-form-field-ripple,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-form-field-underline {
	background-color: currentColor
}

.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-select-value {
	color: inherit
}

.mat-toolbar .mat-input-element {
	caret-color: currentColor
}

.mat-tooltip {
	background: rgba(97, 97, 97, .9)
}

.mat-tree {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	background: #fff
}

.mat-nested-tree-node,
.mat-tree-node {
	font-weight: 400;
	font-size: 14px;
	color: rgba(0, 0, 0, .87)
}

.mat-snack-bar-container {
	color: rgba(255, 255, 255, .7);
	background: #323232;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-simple-snackbar-action {
	color: #ff4081
}

.docs-app-background {
	background: #fafafa
}

.docs-primary-header {
	background: #3f51b5
}

.docs-footer {
	background: #3f51b5;
	color: #fff
}

.is-next-version {
	background: #b71c1c!important
}

.docs-component-category-list-card-summary,
.docs-component-list-card-summary {
	color: rgba(0, 0, 0, .54)
}

.docs-component-viewer-nav-content {
	background: rgba(0, 0, 0, .03)
}

.docs-component-viewer-nav-content::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, .26)
}

.docs-component-viewer-nav-content .docs-nav-content-btn {
	color: rgba(0, 0, 0, .5)
}

.docs-component-viewer-nav-content .docs-nav-content-btn:focus {
	background: rgba(0, 0, 0, .08)
}

.docs-component-viewer-nav-content hr {
	border: none;
	border-top: 1px solid rgba(0, 0, 0, .1)
}

.docs-component-viewer-nav-content a {
	color: rgba(0, 0, 0, .54)
}

.docs-component-viewer-nav-content a.docs-component-viewer-sidenav-item-selected,
.docs-component-viewer-nav-content a:hover {
	color: #3f51b5
}

@media (max-width:720px) {
	.docs-component-viewer-sidenav .docs-component-viewer-nav-content {
		background: 0 0
	}
	.mat-drawer::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, .26)
	}
}

app-component-viewer,
app-component-viewer .mat-tab-label:focus,
guide-viewer,
guide-viewer .mat-tab-label:focus {
	color: rgba(0, 0, 0, .87)
}

.docs-api-h3 {
	border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.docs-api-method-name-cell {
	color: #283593
}

.docs-api-method-parameter-type,
.docs-api-method-returns-type {
	color: #303f9f
}

.docs-api-h1 {
	display: none!important
}

.docs-api>p {
	word-break: break-word
}

.docs-api-class-export-name,
.docs-api-class-name,
.docs-api-class-selector-name,
.docs-api-module-import {
	background: rgba(0, 0, 0, .06)
}

.docs-markdown a {
	text-decoration: none;
	color: #3f51b5
}

.docs-markdown pre {
	background: rgba(0, 0, 0, .01);
	border: .5px solid rgba(0, 0, 0, .03)
}

.docs-markdown h3 .material-icons,
.docs-markdown h4 .material-icons {
	color: rgba(0, 0, 0, .54)
}

.docs-markdown code {
	background: rgba(0, 0, 0, .03)
}

.docs-svg-primary-fill {
	fill: #3f51b5
}

.docs-svg-primary-stroke {
	stroke: #3f51b5
}

.docs-svg-primary-stop-color {
	stop-color: #3f51b5
}

.docs-svg-accent-fill {
	fill: #ff4081
}

.docs-svg-accent-stroke {
	stroke: #ff4081
}

.docs-svg-accent-stop-color {
	stop-color: #ff4081
}

.docs-svg-primary-dark-30-fill {
	fill: #2c397f
}

.docs-svg-primary-dark-30-stroke {
	stroke: #2c397f
}

.docs-svg-primary-dark-30-stop-color {
	stop-color: #2c397f
}

.docs-svg-primary-dark-40-fill {
	fill: #26316d
}

.docs-svg-primary-dark-40-stroke {
	stroke: #26316d
}

.docs-svg-primary-dark-40-stop-color {
	stop-color: #26316d
}

.docs-svg-primary-light-20-fill {
	fill: #6171c8
}

.docs-svg-primary-light-20-stroke {
	stroke: #6171c8
}

.docs-svg-primary-light-20-stop-color {
	stop-color: #6171c8
}

.docs-svg-primary-light-30-fill {
	fill: #7583cf
}

.docs-svg-primary-light-30-stroke {
	stroke: #7583cf
}

.docs-svg-primary-light-30-stop-color {
	stop-color: #7583cf
}

.docs-svg-primary-light-40-fill {
	fill: #8994d6
}

.docs-svg-primary-light-40-stroke {
	stroke: #8994d6
}

.docs-svg-primary-light-40-stop-color {
	stop-color: #8994d6
}

.docs-svg-primary-light-60-fill {
	fill: #b0b8e4
}

.docs-svg-primary-light-60-stroke {
	stroke: #b0b8e4
}

.docs-svg-primary-light-60-stop-color {
	stop-color: #b0b8e4
}

.docs-svg-primary-light-80-fill {
	fill: #d8dbf1
}

.docs-svg-primary-light-80-stroke {
	stroke: #d8dbf1
}

.docs-svg-primary-light-80-stop-color {
	stop-color: #d8dbf1
}

.docs-svg-primary-light-85-fill {
	fill: #e1e4f5
}

.docs-svg-primary-light-85-stroke {
	stroke: #e1e4f5
}

.docs-svg-primary-light-85-stop-color {
	stop-color: #e1e4f5
}

.docs-api table,
.docs-markdown-table {
	color: rgba(0, 0, 0, .87)
}

.docs-api th,
.docs-markdown-th {
	background: #f5f5f5
}

.docs-api td,
.docs-markdown-td {
	border: 1px solid rgba(0, 0, 0, .03)
}

example-viewer .docs-example-viewer-wrapper {
	border: 1px solid rgba(0, 0, 0, .03);
	box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
	margin: 4px
}

example-viewer .docs-example-viewer-title {
	color: rgba(0, 0, 0, .54);
	background: rgba(0, 0, 0, .03)
}

example-viewer .docs-example-source-copy {
	color: rgba(0, 0, 0, .38);
	right: 8px
}

[dir=rtl] example-viewer .docs-example-source-copy {
	right: auto;
	left: 8px
}

example-viewer .docs-example-source {
	border-bottom: 1px solid rgba(0, 0, 0, .12);
	overflow: auto
}

app-footer .docs-footer-links a {
	color: #fff
}

.docs-guide-list .docs-guide-item {
	color: #3f51b5
}

app-homepage .docs-header-background {
	background: #3f51b5
}

app-homepage .docs-header-headline {
	color: #fff
}

app-homepage .docs-header-start {
	color: #3f51b5
}

app-homepage .docs-homepage-row {
	color: rgba(0, 0, 0, .54)
}

app-navbar {
	color: #fff
}

app-navbar .docs-navbar,
app-navbar .docs-navbar-header {
	background: #3f51b5
}

.docs-toc-container {
	border-left: 4px solid #3f51b5
}

.docs-toc-container .docs-link {
	color: rgba(0, 0, 0, .54);
	transition: color .1s
}

.docs-toc-container .docs-link.docs-active,
.docs-toc-container .docs-link:hover {
	color: #3f51b5
}

.hljs {
	display: block;
	overflow-x: auto;
	padding: 1em;
	background: #fafafa;
	color: #37474f;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	font: 300 100%/1 Roboto Mono, monospace;
	font-size: 14px
}

.hljs-section,
.hljs>::-moz-selection {
	background-color: #d6edea
}

.hljs-section,
.hljs>::selection {
	background-color: #d6edea
}

.hljs-comment {
	color: #616161;
	font-style: italic
}

.hljs-meta,
.hljs-regexp,
.hljs-selector-tag,
.hljs-tag {
	color: #9c27b0
}

.hljs-string,
.hljs-subst {
	color: #0d904f
}

.hljs-number,
.hljs-template-variable,
.hljs-variable {
	color: #80cbc4
}

.hljs-attribute,
.hljs-keyword,
.hljs-name,
.hljs-type {
	color: #3b78e7
}

.hljs-built_in,
.hljs-builtin-name,
.hljs-bullet,
.hljs-function>.hljs-title,
.hljs-link,
.hljs-symbol,
.hljs-title {
	color: #6182b8
}

.hljs-params {
	color: #d81b60
}

.hljs-addition {
	color: #3b78e7;
	display: inline-block;
	width: 100%
}

.hljs-deletion {
	color: #e53935;
	display: inline-block;
	width: 100%
}

.hljs-selector-class,
.hljs-selector-id {
	color: #8796b0
}

.hljs-emphasis {
	font-style: italic
}

.hljs-strong {
	font-weight: 700
}

.hljs-link {
	text-decoration: underline
}

/* select dropdown css */
.pure-checkbox input[type=checkbox]:focus+label:before,
.pure-checkbox input[type=checkbox]:hover+label:before {
	border-color: #0079fe;
	background-color: #f2f2f2
}

.pure-checkbox input[type=checkbox]+label {
	color: #000;
	font-size:12px;

}

.pure-checkbox input[type=checkbox]+label:before {
	color: #0079fe;
	border: 1px solid #0079fe
}

.pure-checkbox input[type=checkbox]+label:after {
	background-color: #0079fe;
	border-color: #fff
}

.pure-checkbox input[type=checkbox]:disabled+label:before {
	border-color: #ccc
}

.pure-checkbox input[type=checkbox]:disabled:checked+label:before {
	background-color: #ccc
}

.pure-checkbox input[type=radio]:checked+label:before {
	background-color: #fff
}

.pure-checkbox input[type=checkbox]:checked+label:before {
	background: #0079fe
}

.single-select-mode .pure-checkbox input[type=checkbox]:focus+label:before,
.single-select-mode .pure-checkbox input[type=checkbox]:hover+label:before {
	border-color: #0079fe;
	background-color: #f2f2f2
}

.single-select-mode .pure-checkbox input[type=checkbox]+label {
	color: #000
}

.single-select-mode .pure-checkbox input[type=checkbox]+label:before {
	color: transparent!important;
	border: 0 solid #0079fe
}

.single-select-mode .pure-checkbox input[type=checkbox]+label:after {
	background-color: transparent!important;
	border-color: #0079fe
}

.single-select-mode .pure-checkbox input[type=checkbox]:disabled+label:before {
	border-color: #ccc
}

.single-select-mode .pure-checkbox input[type=checkbox]:disabled:checked+label:before {
	background-color: #ccc
}

.single-select-mode .pure-checkbox input[type=radio]:checked+label:before {
	background-color: #fff
}

.single-select-mode .pure-checkbox input[type=checkbox]:checked+label:before {
	background: 0 0!important
}
.c-btn {
	display: inline-block;
    border: 1px solid #d2d6de;
    line-height: 1.25;
    border-radius: 3px;
    font-size: .85rem;
    padding: 0px 10px !important;
    cursor: pointer;
    align-items: center;
    min-height: 32px !important;
    box-sizing: border-box;
	background-color: #ffffff;
}
table.dataTable tbody th, table.dataTable tbody td{
	border: 1px solid #ddd !important;
}
.table-bordered > tbody > tr > td{
    border: 1px solid #ddd !important;
}
.table > thead:first-child > tr:first-child > th{
	border-top: 1px solid #ddd !important;
}

jw-modal .jw-modal-background {
	z-index: 998 !important;
}

jw-modal .jw-modal {
	z-index: 1000 !important;
}

.main-header {
    z-index: 999 !important;
}

.endorsement_h .jw-modal {
	height:90vh !important;
}
.list-area{
	box-shadow: 0 1px 5px #959595;
}
.mat-datepicker-toggle{
    position: absolute;
    top: -3px;
    right: 16px;
}

.ui-sortable-column-icon:before {
    font-family: FontAwesome;
}

.ui-sortable-column-icon.ui-icon {
    background-image: none; 
    text-indent: 0;
    margin: 0 0 0 .5em;
    height: 20px;
}

.ui-sortable-column-icon.ui-icon.ui-icon-carat-2-n-s.ui-icon-triangle-1-n,
.ui-sortable-column-icon.ui-icon.ui-icon-carat-2-n-s.ui-icon-triangle-1-s {
    margin: 0px 0px 0px 0px;
}

.ui-sortable-column-icon.ui-icon.ui-icon-carat-2-n-s.ui-icon-triangle-1-n {
    top: -2px;
}

.ui-sortable-column-icon.ui-icon.ui-icon-carat-2-n-s.ui-icon-triangle-1-s {
    top: 4px;
}

.ui-sortable-column-icon.ui-icon.ui-icon-carat-2-n-s:before { 
    content: "\f0dc"; 
}

.ui-sortable-column-icon.ui-icon.ui-icon-carat-2-n-s.ui-icon-triangle-1-n:before {
    content: "\f0d8";
}

.ui-sortable-column-icon.ui-icon.ui-icon-carat-2-n-s.ui-icon-triangle-1-s:before {
    content: "\f0d7";
} 

.c-btn{
	font-size:12px;
}
table.dataTable{
	font-size: 13px;
}
.ng_table .ui-table-wrapper table{
	border-collapse: collapse;
    width: 100%;
}
.table{
	width:100% !important;
}
.data-quote-search{
	width: 100%;
    border: 1px solid #ddd;
	padding: 6px;
	box-sizing: border-box;
	outline: none;
}
.adminlist tr td:nth-child(4){
width:34%;
}
.autocomplete-container .suggestions-container{
	z-index: 1;
}
.ql-editor{
    background-color: #ffffff;
}

.ck-editor__editable_inline {
    min-height: 300px;
}

.ngx-dropdown-container .ngx-dropdown-list-container ul li{
	padding: 5px 10px !important;
}
.ngx-dropdown-container .ngx-dropdown-list-container ul li:hover{
	background: #1565C0;
	color:#ffffff !important;
}
.ngx-dropdown-container .ngx-dropdown-list-container{
padding: 0 !important;
}
.ngx-dropdown-container .ngx-dropdown-list-container .search-container label{
	left:10px !important;
}
.ngx-dropdown-container .ngx-dropdown-list-container .search-container input{
	padding-left: 10px !important;
}
.available-items{
	height: 50vh;
	overflow-y: auto;
}
.ngx-dropdown-container .ngx-dropdown-list-container{
	overflow-y: hidden !important;
	overflow-x: hidden !important;
}
.ngx-dropdown-container button{
border-radius: 0 !important;
border-color: #b0b3b9 !important;
outline: none;
}
#tempolicy_num{
	display:inline;
	width:100px;
}
#document-Iframe-modal .jw-modal-body{
    height:100%;
    width:100%
}
#document-Iframe-modal .jw-modal {
	position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -0%);
    transform: translate(-50%, -0%);
    height: 100vh;
    width: 100%;
    overflow: auto;
}
@media (max-width: 767px){
	.dataTables_wrapper{
		overflow: auto;
	}
	#Licensesinfo{
		overflow: auto;
	}
}

/* Disable browser close icon for IE */
input[type="search"]::-ms-clear {  display: none; width : 0; height: 0; }
input[type="search"]::-ms-reveal {  display: none; width : 0; height: 0; }
/* Disable browser close icon for Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.header_logo {
	height: 60px;
	/* width: 225px !important; */
  }
  .navbar-brand img {
    width: 100% !important;
}
  .disableCard{
    background-color: #dddddd !important;
  }
  .disableCard:hover{
	cursor: not-allowed !important;
	color: black !important;
  }

  @media only screen and (max-width: 1285px) {
    .quotes_tbl{
    overflow-x:auto !important;
    }
}
@media only screen and (max-width: 1035px) {
    .brokers_tbl{
    overflow-x:auto !important;
    }
}

input[readonly] {
	pointer-events: none;
  }
 table th{
	font-size: 12px;
 
 }
 .modal_lglg{
	width: 90% !important;
	background: #fff;
    margin: 5% auto;
	
  }
  @media only screen and (min-device-width: 300px){
	.YesNo{
		display: block !important;
	}
  }
  .upload-container {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .upload-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .upload-button{
   
    color: white;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 3px 5px;
    cursor: pointer;
    border-radius: 41px;
    border: 1px solid transparent ;
    box-shadow: inset 0 0 0 50px #3468c1;
    background-color: #337ab7;
}
  .uploaded-files {
    margin-top: 20px;
  }
  
  .uploaded-files div {
    margin-bottom: 10px;
  }
  .upload-container.drag-over {
    border: 3px dashed rgb(22, 179, 48); /* Example color for highlighting */
    transition: border 0.3s ease; /* Optional: add a smooth transition effect */
  }